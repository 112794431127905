/* @import url('https://rsms.me/inter/inter.css'); */
@import url('core/fonts/InterWeb/inter.css');

html, body {
  padding: 0;
  margin: 0;
}

html { 
  font-family: 'Inter', sans-serif; width: 100%; height: 100vh; overflow-y: scroll;
}
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  display: flex;
  
}

a{
  text-decoration: none;
  color: black;
}

#root{
  width: 100%;
  flex: 1;
  flex-direction: column;
}

.react-toggle {
  margin-right: 8px;
  vertical-align: middle;
}

label, .label-text {
  vertical-align: middle;
  font-weight: normal;
  margin-bottom: 0; /* override bootstrap */
}
